/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    em: "em",
    ul: "ul",
    li: "li",
    code: "code",
    a: "a",
    strong: "strong",
    img: "img",
    h3: "h3",
    pre: "pre",
    br: "br"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "pointers"
  }, "Pointers"), "\n", React.createElement(_components.p, null, "A pointer is a variable that contains an address in memory.\nIt points to, or ", React.createElement(_components.em, null, "refers"), " to some other data.\nYou can think of it like an arrow to that value."), "\n", React.createElement(_components.p, null, "Rust has two regular types of pointers called references.\nThey’re recognized by the ampersand in front of a variable name."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "&"), " for an immutable reference (eg. ", React.createElement(_components.code, null, "&my_variable"), ")."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "&mut"), " for a mutable reference. (eg. ", React.createElement(_components.code, null, "&mut my_variable"), ")"), "\n"), "\n", React.createElement(_components.p, null, "References to a value don’t own that value, they ", React.createElement(_components.em, null, "borrow"), " that value.\nIn other words, the reference can disappear and the value it pointed at will still exist."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The Rust programming language book has ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/book/ch04-00-understanding-ownership.html"
  }, "a great chapter on ownership in Rust"), ".")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Rust’s ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Race_condition"
  }, "data race"), " prevention rules dictate that for a given piece of data in any particular scope:"), React.createElement(_components.p, null, "You can have only ", React.createElement(_components.strong, null, "one mutable"), " reference to that data ", React.createElement(_components.strong, null, "OR"), " you can have ", React.createElement(_components.strong, null, "multiple immutable"), " references to that data.\nNever both at the same time.")), "\n", React.createElement(_components.p, null, "You might recognize the term “shared reference” from compiler errors.\nA while back, I got a compiler error that said something like ", React.createElement(_components.code, null, "blablabla, because it is behind a shared reference"), ".\nThis confused me, I got that error while the message indicated a reference that was the only one in the entire program.\nHow could it be “shared” then?\nTurns out that in many cases, “shared reference” is an other way to say “immutable reference”."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "An other name for an immutable reference is a shared reference."), "\n", React.createElement(_components.li, null, "An other name for a mutable reference is an exclusive reference."), "\n"), "\n", React.createElement(_components.h2, {
    id: "smart-pointers"
  }, "Smart pointers"), "\n", React.createElement(_components.p, null, "Those references are regular pointers that only point to some data, they don’t have any other capabilities.\nSmart pointers can have extra capabilities.\nThey are data structures that not only act like a pointer, but have additional metadata.\nThey use that extra data to enable behavior regular pointers could not have."), "\n", React.createElement(_components.p, null, "You could say that those pointers are … smart"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "https://i.imgur.com/7RUWtXX.jpg",
    alt: "Rick Dalton pointing in recognition"
  })), "\n", React.createElement(_components.p, null, "Smart pointers are usually implemented using a struct."), "\n", React.createElement(_components.p, null, "An other difference with regular references, smart pointers usually own the data they point to.\nIn other words: when the smart pointer gets dropped, the data they point to gets dropped."), "\n", React.createElement(_components.p, null, "Most smart pointers implement the ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Deref.html"
  }, React.createElement(_components.code, null, "Deref")), ", and ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Drop.html"
  }, React.createElement(_components.code, null, "Drop")), " traits."), "\n", React.createElement(_components.h3, {
    id: "string"
  }, "String"), "\n", React.createElement(_components.p, null, "If you’ve programmed in Rust before, chances are great you already used smart pointer, even if you didn’t know you were."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/string/struct.String.html"
  }, React.createElement(_components.code, null, "String")), " is a smart pointer."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let s1 = String::from(\"hello\");\n")), "\n", React.createElement(_components.p, null, "On the left of the following image is the data that is stored on the stack.\nOn the right, the data that is stored on the heap."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Psst, I wrote about ", React.createElement(_components.a, {
    href: "/garden/rust-stack-heap"
  }, "the stack and the heap in Rust"))), "\n", React.createElement(_components.p, null, "On the stack is our ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/string/struct.String.html"
  }, React.createElement(_components.code, null, "String")), ", named ", React.createElement(_components.code, null, "s1"), ".\nIt’s a struct that not only has a pointer to a specific location on the heap (in ", React.createElement(_components.code, null, "ptr"), ").\nIt has additional metadata, like the length of the string (in ", React.createElement(_components.code, null, "len"), "), and the amount of bytes that string occupies (in ", React.createElement(_components.code, null, "capacity"), ").\nThe distinction between those last 2 fields is not important right now, what is important is that the string has additional metadata associated with it."), "\n", React.createElement(_components.p, null, "The heap stores the contents of that string in consecutive memory addresses.\nIn this case the letters ", React.createElement(_components.code, null, "h"), ", ", React.createElement(_components.code, null, "e"), ", ", React.createElement(_components.code, null, "l"), ", ", React.createElement(_components.code, null, "l"), ", ", React.createElement(_components.code, null, "o"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/6a302bc3ac7f887762291074901d164a/trpl04-01.svg",
    alt: "a String"
  })), "\n", React.createElement(_components.h3, {
    id: "deref"
  }, "Deref"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Deref.html"
  }, React.createElement(_components.code, null, "Deref")), " trait allows a struct that implements it to behave like a pointer, instead of as a regular struct that holds a pointer in a field.\nThat way you can write code that works for references, and smart pointers will work with it."), "\n", React.createElement(_components.p, null, "The dereference operator, ", React.createElement(_components.code, null, "*"), ", follows a pointer to the value it is pointing to.\nCalling it on a regular struct wouldn’t work, but a struct that implements ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Deref.html"
  }, React.createElement(_components.code, null, "Deref")), " knows what to do when that happens."), "\n", React.createElement(_components.p, null, "To implement the ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Deref.html"
  }, React.createElement(_components.code, null, "Deref")), " trait, you have to implement a method named ", React.createElement(_components.code, null, "deref"), ".\nIt takes an immutable reference to ", React.createElement(_components.code, null, "self"), ", and returns an immutable reference to an other type."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "In my opinion, the ", React.createElement(_components.code, null, "deref"), " method is incredibly confusing naming.\n", React.createElement(_components.code, null, "deref"), " doesn’t dereference at all, it returns a reference.\nThe compiler knows how to dereference ", React.createElement(_components.em, null, "that"), " reference.")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box<T>")), " type is a smart pointer that implements ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Deref.html"
  }, React.createElement(_components.code, null, "Deref")), "."), "\n", React.createElement(_components.p, null, "When you use the dereference operator a ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box<T>")), ", under the hood, a call to the ", React.createElement(_components.code, null, "deref"), " method happens first.\n", React.createElement(_components.code, null, "deref"), " returns another reference.\nFor ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box<T>")), ", that’s a reference to the inner type, the ", React.createElement(_components.code, null, "T"), " in ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box<T>")), ".\nThe compiler then dereferences it by following ", React.createElement(_components.em, null, "that"), " reference."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let num = 5;\nlet boxed_num = Box::new(num);\n\nassert_eq!(5, num);\nassert_eq!(5, *boxed_num);\n")), "\n", React.createElement(_components.p, null, "In the first ", React.createElement(_components.code, null, "assert_eq"), ", we directly compare ", React.createElement(_components.code, null, "5"), " with ", React.createElement(_components.code, null, "num"), ".", React.createElement(_components.br), "\n", "In the second ", React.createElement(_components.code, null, "assert_eq"), ", we compare ", React.createElement(_components.code, null, "5"), " with the result of using the dereference operator on a boxed value."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "*boxed_num"), " is equivalent to writing ", React.createElement(_components.code, null, "*(boxed_num.deref())"), "."), "\n", React.createElement(_components.h3, {
    id: "drop"
  }, "Drop"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Drop.html"
  }, React.createElement(_components.code, null, "Drop")), " trait allows you to customize the code that runs when an instance of that struct goes out of scope.\nIt is used to release resources like network connections, files, and used memory."), "\n", React.createElement(_components.p, null, "An example usage: when the owner of a ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/boxed/struct.Box.html"
  }, React.createElement(_components.code, null, "Box<T>")), " goes out of scope,\nnot only is the ", React.createElement(_components.code, null, "Box"), " popped off the stack, the ", React.createElement(_components.code, null, "T"), " that uses memory on the heap is deallocated."), "\n", React.createElement(_components.p, null, "To implement the ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Drop.html"
  }, React.createElement(_components.code, null, "Drop")), " trait, you have to implement a method named ", React.createElement(_components.code, null, "drop"), ".\nIt takes a mutable reference to ", React.createElement(_components.code, null, "self"), ", and doesn’t return anything. (well, it returns the unit type, the empty tuple, ", React.createElement(_components.code, null, "()"), ")"), "\n", React.createElement(_components.p, null, "That ", React.createElement(_components.code, null, "drop"), " is called automatically when the owner of a value goes out of scope.\nIn other words: if a variable leaves the curly bois ", React.createElement(_components.code, null, "{}"), " that denote a scope, ", React.createElement(_components.code, null, "drop"), " is called on that variable."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "It’s not allowed to call the ", React.createElement(_components.code, null, "drop"), " method in the ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/ops/trait.Drop.html"
  }, React.createElement(_components.code, null, "Drop")), " trait manually.\nAt least not directly.\nDoing so during a scope would cause the method to be called again, automatically, at the end of that scope."), React.createElement(_components.p, null, "That would cause unwanted situations, or the infamous ", React.createElement(_components.em, null, "double free"), " error where you try to deallocate a piece of memory twice.\nIf you want to call ", React.createElement(_components.code, null, "drop"), " before the end of the scope, call it via ", React.createElement(_components.a, {
    href: "https://doc.rust-lang.org/std/mem/fn.drop.html"
  }, React.createElement(_components.code, null, "std::mem::drop")), ".\nThat will make sure ", React.createElement(_components.code, null, "drop"), " is only called once.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
